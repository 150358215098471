<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    midium
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col>
          <div style="max-width:300px">
            <v-text-field
              v-model="data.stampTime"
              label="打刻時間:"
              filled
              readonly
            ></v-text-field>
          </div>
        </v-col>
        <v-col>
          <TimePicker
            v-model="data.start"
            label="勤務開始"
            prepend-inner-icon="mdi-clock-start"
            max-width="300"
            outlined
            :rules="[Rules.Required]"
            :disabled="canFix()"
          />
        </v-col>
        <v-col>
          <TimePicker
            v-model="data.end"
            label="勤務終了"
            prepend-inner-icon="mdi-clock-end"
            max-width="300"
            outlined
            :rules="[Rules.Required(data.end, true)]"
            :disabled="canFix()"
          />
        </v-col>
        <!-- <v-col>
          <v-text-field
            v-model.number="data.distance"
            label="移動距離"
            suffix="km"
            max-width="300"
            type="number"
            prepend-inner-icon="mdi-map-marker-distance"
            outlined
          ></v-text-field>
        </v-col> -->
      </v-row>

      <v-row>
        <v-col>
          <v-radio-group
            v-model="selectAssist"
            row
            label="応援:"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsAssist.Default"></v-radio>
            <v-radio label="あり" :value="SelectsAssist.Assist"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import Attendance from "@/models/Attendance";

import ShowDialogs from "@/mixins/showDialogs";
const SelectsAssist = {
  Default: "0",
  Assist: "1"
};
export default {
  name: "AttendanceFixTemporaryDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  computed: {
    Title() {
      const title = this.edit ? "勤怠修正" : "勤怠登録";
      return title;
    }
  },
  data() {
    return {
      date: "",
      data: {},
      edit: true,
      selectAssist: SelectsAssist.Default,
      SelectsAssist
    };
  },
  methods: {
    async postAttendance(param) {
      console.log("post", param);

      try {
        await this.$post(this.Paths.attendance, param);
      } catch (e) {
        console.log("posterror", e);
        this.$error(e.message);
      }
    },
    async putAttendance(param) {
      console.log("put", param);

      try {
        await this.$put(this.Paths.attendance, param);
      } catch (e) {
        console.log("puterror", e);
        this.$error(e.message);
      }
    },
    canFix() {
      if (this.$store.state.permitFixTime == 0) return true;
      return false;
    },
    addInfo(info) {
      const remarks = this.remarks;
      if (!remarks.includes(info)) {
        remarks.push(info);
      } else {
        const index = remarks.indexOf(info);
        this.remarks = remarks.filter((x, i) => i !== index);
      }
    },
    close(result) {
      this.resolve(result);
      this.$store.commit("user/logout");
      this.$close();
    },
    complete() {
      if (!this.$refs.form.validate()) return;

      const data = { ...this.data };
      const args = this.args;
      Object.keys(args).forEach(key => {
        if (this.data[key]) args[key] = data[key];
      });

      const payLoad = new Attendance(
        data.recordid,
        data.employee,
        data.affiliation,
        data.shiftid,
        data.shiftpatternid,
        data.datestr,
        data.start,
        data.end,
        data.distance,
        data.brektime ? data.brektime : "",
        data.work ? data.work : "",
        data.over ? data.over : "",
        data.midnight ? data.midnight : "",
        data.hadmeal,
        data.workclassificationid,
        data.amount,
        data.reserveid1,
        data.nightShift,
        data.careShu,
        data.careKbn,
        data.timeKbn,
        data.nightGuard,
        data.emergencyFlag,
        data.midnightTel,
        String(this.selectAssist)
      );

      if (this.$isEmpty(data.recordid)) {
        const result = this.postAttendance(payLoad);
        console.log("post result", result);
      } else {
        this.putAttendance(payLoad);
      }

      console.log("complete", payLoad);
      this.$store.commit("user/logout");
      this.$close(data);
    }
  },
  created() {
    console.log("created", this.args);
    const args = this.args;
    if (!this.$isEmpty(args)) {
      if (this.$isEmpty(args["recordid"])) {
        this.edit = false;
      } else {
        this.edit = true;
      }

      if (!Array.isArray(args)) {
        this.data = { ...args };
      } else {
        const data = {};
        args.forEach(select => {
          Object.keys(select).forEach(key => {
            if (!data[key]) data[key] = select[key];
            else if (data[key] !== select[key]) data[key] = null;
          });
        });
        this.data = data;
      }
      // if (this.data.hadmeal === "1") this.selectMeal = SelectsMeal.Eat;
      if (this.data.assistFlag === "1")
        this.selectAssist = SelectsAssist.Assist;
    }
  }
};
</script>

<style></style>
