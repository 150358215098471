
import Vue, { PropType } from "vue";
import { DateTime } from "luxon";
import { OverworkFlowDat } from "@/models/transaction/OverworkFlowDat";
import { VForm } from "vuetify/lib";
import { PetitionShiftSearch } from "@/models/petition/PetitionResponse";
import OvertimeWorkForm from "@/components/form/OvertimeWorkForm.vue";
import { Attendance } from "@/models/transaction/Attendance";

const message =
  "勤務時間変更を同日に申請している場合は、休憩時間に合わせないと申請できません。";

interface VForm extends Vue {
  reset(): void;
  resetValidation(): void;
  validate(): boolean;
}

export default Vue.extend({
  name: "OvertimeWorkApplication",
  components: { VForm, OvertimeWorkForm },
  props: {
    date: {
      type: Object as PropType<DateTime>,
      required: true
    },
    shift: {
      type: Object as PropType<PetitionShiftSearch>,
      required: true
    },
    employeeId: {
      type: Number,
      required: true
    },
    affiliationId: {
      type: Number,
      required: true
    },
    attendance: {
      type: Object as PropType<Attendance>,
      required: true
    },
    isNightShift: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: new OverworkFlowDat({
        recordId: 0,
        employeeId: this.employeeId,
        affiliationId: this.affiliationId,
        shiftId: this.shift.shiftId,
        overtimeWorkDate: this.date.toISO(),
        overtimeWorkEnd: "",
        overtimeWorkBreak: "00:00",
        isNightShift: this.isNightShift
      }),
      message
    };
  },
  methods: {
    async onSubmit() {
      const form = this.$refs.form as VForm;
      if (!form.validate()) {
        this.$alert(
          "warning",
          "入力に誤りがあります。\n入力内容を確認してください。\n\n勤務時間変更を同日に申請されている場合は、\n休憩時間が合うように入力してください。"
        );
        return;
      }
      this.form.requestDate = DateTime.local().toISO();
      (this.form.startTimeStamp = this.attendance?.startTimeUnix
        ? DateTime.fromSeconds(this.attendance?.startTimeUnix).toFormat("HH:mm")
        : undefined),
        (this.form.endTimeStamp = this.attendance?.endTimeUnix
          ? DateTime.fromSeconds(this.attendance?.endTimeUnix).toFormat("HH:mm")
          : undefined);
      const result = await this.$post(
        this.Paths.petition.insert.overtimeWork,
        this.form
      );
      if (result) {
        this.$alert("success", "申請に成功しました。");
        return true;
      } else {
        this.$alert("error", "申請に失敗しました。");
        return false;
      }
    }
  }
});
