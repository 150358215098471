import { VacationFlowDat } from "../transaction/VacationFlowDat";
import { VacationDaysDatKey } from "../transaction/VacationDaysDatKey";

/**
 * 申請画面用シフト検索リクエスト
 */
export class PetitionShiftTableSearchRequest {
  dateFrom: Date;
  dateTo: Date;
  affiliationId: number;

  constructor(props: { dateFrom: Date; dateTo: Date; affiliationId: number }) {
    this.dateFrom = props.dateFrom;
    this.dateTo = props.dateTo;
    this.affiliationId = props.affiliationId;
  }
}

/**
 * 勤務時間変更画面シフト検索モデル
 */
export class PetitionShiftSearchRequest {
  attendanceDate: string;
  employeeId: number;
  affiliationId: number;

  constructor(props: PetitionShiftSearchRequest) {
    this.attendanceDate = props.attendanceDate;
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
  }
}

/**
 * 勤務時間変更画面実績検索モデル
 */
export class PetitionAttendanceSearchRequest {
  attendanceDate: string;
  employeeId: number;
  affiliationId: number;

  constructor(props: PetitionAttendanceSearchRequest) {
    this.attendanceDate = props.attendanceDate;
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
  }
}

/**
 * 休暇申請リクエスト
 */
export class VacationApplicationRequest {
  vacationFlow: VacationFlowDat;
  vacationDays: VacationDaysDatKey[];

  constructor(props: VacationApplicationRequest) {
    this.vacationFlow = props.vacationFlow;
    this.vacationDays = props.vacationDays;
  }
}

/**
 * シフトパターン検索リクエスト
 */
export class PetitionShiftPatternSearchRequest {
  affiliationId: number;

  constructor(props: PetitionShiftPatternSearchRequest) {
    this.affiliationId = props.affiliationId;
  }
}
