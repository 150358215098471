import { render, staticRenderFns } from "./TemporaryPasswordChangeDialog.vue?vue&type=template&id=477d0921"
import script from "./TemporaryPasswordChangeDialog.vue?vue&type=script&lang=js"
export * from "./TemporaryPasswordChangeDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports