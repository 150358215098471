
import Vue from "vue";
import {
  VCard,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VBtn,
  VIcon
} from "vuetify/lib";
import MonthPicker from "@/components/picker/MonthPicker.vue";
import PetitionTable from "@/components/petition/PetitionTable.vue";
import { DateTime } from "luxon";

const startDate = 21;
const today = DateTime.local();
const date =
  today.day >= startDate
    ? today.plus({ month: 1 }).startOf("month")
    : today.startOf("month");
const month = date.toFormat("yyyy-MM");

export default Vue.extend({
  name: "PetitionDialogContent",
  components: {
    VCard,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VBtn,
    VIcon,
    MonthPicker,
    PetitionTable
  },
  props: {
    value: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      month: month,
      title: "申請画面"
    };
  },
  computed: {
    modelValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    },
    date: {
      get(): DateTime {
        return DateTime.fromFormat(this.month, "yyyy-MM");
      }
    }
  },
  methods: {
    onRight() {
      this.month = this.date.plus({ month: 1 }).toFormat("yyyy-MM");
    },
    onLeft() {
      this.month = this.date.minus({ month: 1 }).toFormat("yyyy-MM");
    },
    onClose() {
      this.$emit("close");
      this.modelValue = false;
    }
  }
});
