export class ShiftFlowDat {
  recordId: number;
  requestDate?: string | null;
  date?: string | null;
  employeeId?: number | null;
  affiliationId?: number | null;
  shiftId?: string | null;
  preShiftpatternId?: number | null;
  preShiftpatternNm?: string | null;
  preStartTime?: string | null;
  preEndTime?: string | null;
  preMorningBreakTime?: string | null;
  preBreakTime?: string | null;
  preMidnightBreakTime?: string | null;
  afterShiftpatternId?: number | null;
  afterShiftpatternNm?: string | null;
  afterStartTime?: string | null;
  afterEndTime?: string | null;
  afterMorningBreakTime?: string | null;
  afterBreakTime?: string | null;
  afterMidnightBreakTime?: string | null;
  remarks?: string | null;
  latestApprovalLevel?: number | null;
  latestApprovalFlag?: string | null;
  latestApprovalDate?: string | null;

  constructor(props: ShiftFlowDat) {
    this.recordId = props.recordId;
    this.requestDate = props.requestDate;
    this.date = props.date;
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
    this.shiftId = props.shiftId;
    this.preShiftpatternId = props.preShiftpatternId;
    this.preShiftpatternNm = props.preShiftpatternNm;
    this.preStartTime = props.preStartTime;
    this.preEndTime = props.preEndTime;
    this.preMorningBreakTime = props.preMorningBreakTime;
    this.preBreakTime = props.preBreakTime;
    this.preMidnightBreakTime = props.preMidnightBreakTime;
    this.afterShiftpatternId = props.afterShiftpatternId;
    this.afterShiftpatternNm = props.afterShiftpatternNm;
    this.afterStartTime = props.afterStartTime;
    this.afterEndTime = props.afterEndTime;
    this.afterMorningBreakTime = props.afterMorningBreakTime;
    this.afterBreakTime = props.afterBreakTime;
    this.afterMidnightBreakTime = props.afterMidnightBreakTime;
    this.remarks = props.remarks;
    this.latestApprovalLevel = props.latestApprovalLevel;
    this.latestApprovalFlag = props.latestApprovalFlag;
    this.latestApprovalDate = props.latestApprovalDate;
  }
}
